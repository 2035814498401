<template>
    <el-main>
        <el-form ref="form" :model="form" label-width="160px" :rules="rules">
            <el-form-item label="开启独立第三方：">
                <el-switch v-model="form.is_open" :active-value="1" :inactive-value="0">
                </el-switch>
            </el-form-item>
            <template v-if="form.is_open">
                <el-form-item label="开放平台APPID：" prop="app_id">
                    <el-input v-model="form.app_id"></el-input>
                </el-form-item>
                <el-form-item label="开放平台Token：" prop="token">
                    <el-input v-model="form.token"></el-input>
                </el-form-item>
                <el-form-item label="开放平台AES Key：" prop="aes_key">
                    <el-input v-model="form.aes_key" prop="token"></el-input>
                </el-form-item>
                <el-form-item label="开放平台Secret：" prop="secret">
                    <el-input v-model="form.secret"></el-input>
                </el-form-item>
            </template>
        </el-form>
        <Preservation @preservation="preservation"></Preservation>
    </el-main>
</template>
  
<script>
import Preservation from '@/components/preservation';
export default {
    components: {
        Preservation
    },
    data () {
        return {
            form: {
                is_open: 0,
                app_id: '',
                secret: '',
                token: '',
                aes_key: ''
            },
            rules: {
                app_id: [{ required: true, message: '请填写开放平台APPID', trigger: 'blur' }],
                token: [{ required: true, message: '请填写开放平台Token', trigger: 'blur' }],
                aes_key: [{ required: true, message: '请填写开放平台AES Key', trigger: 'blur' }],
                secret: [{ required: true, message: '请填写开放平台Secret', trigger: 'blur' }]
            }
        };
    },
    created () {
        this.getInfo()
    },
    methods: {
        getInfo () {
            this.$axios.post(this.$api.serviceProvider.thirdSetInfo).then(res => {
                if (res.code == 0) {
                    let info = res.result
                    this.form = {
                        is_open: info ? info.is_open : 0,
                        app_id: info ? info.app_id : '',
                        secret: info ? info.secret : '',
                        token: info ? info.token : '',
                        aes_key: info ? info.aes_key : ''
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        preservation () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$axios.post(this.$api.serviceProvider.thirdSetAdd, this.form).then(res => {
                        if (res.code == 0) {
                            this.$message.success('保存成功');
                            this.getInfo()
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });

        }
    }
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>
  